import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import _ from "underscore";
import SelectCam from './SelectCam';
import { check } from '../../lib/HTTP';
const audioBip = new Audio('/sound/bip.mp3');
const audioSuccess = new Audio('/sound/success.mp3');
const audioError = new Audio('/sound/error.mp3');

function Scan() {
  
  const [bgColor, setBgColor] = useState("bg-white");
  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);
  let last;
  let lastDate = new Date();  
  let waitingAPI = false;
  const [deviceId, setDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);
  const [waiting, setWating] = useState(false);

  const fetchDevices = async () => {
    await navigator.mediaDevices.getUserMedia( {video: true,audio: true,})
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    const videoinputs = _.where(mediaDevices, {kind: "videoinput"});
    //console.log("videoinputs: ", videoinputs)
    setDevices(videoinputs);
    if(videoinputs?.length){
      const default_deviceId = localStorage.getItem("default_deviceId");
      if(default_deviceId && _.findWhere(videoinputs, {deviceId: default_deviceId})){
        setDeviceId(default_deviceId);
      }else{
        setDeviceId(videoinputs[0].deviceId);
      }      
    }
  }

  const onResult=(result, error) => {
    //console.info("i am in");
    if (!!result) {
      detectData(result?.text);
    }

    if (!!error) {
      //console.info(error);
    }
  }

  const detectData = async (text) => {
    if(waitingAPI || (text===last && ((Math.abs(new Date() - lastDate)/1000)<3))) return;
    setWating(true);
    waitingAPI = true;
    audioBip.play();
    
    last = text;
    lastDate = new Date();
    const response = await check(text).catch( (error) => {
      console.log("error catchx :", error);
      setData({message: "Not Found", status: "notfound"});
      setStatus("notfound");
      setBgColor("bg-red-600");
    }); 
    
    if( response?.data?.data ){
      setData(response?.data?.data);
      setStatus(response?.data?.status);
      setBgColor(response?.data?.status==="success" ? "bg-green-700" : response?.data?.status==="exist" ? "bg-orange-500" : "bg-white")
    }else{
      setData({message: "Not Found", status: "notfound"});
      setStatus("notfound");
      setBgColor("bg-red-600");
    }
    
    if( response?.data?.status==="success" ){
      //setData(response?.data?.data);
      await audioSuccess.play();
    }else{
      await audioError.play();
    }
    setWating(false);
    waitingAPI = false;
  }

  const resetData = () => {
    setData(null);
    setStatus(null);
    setBgColor("bg-white");
  }

  useEffect(() => {
    fetchDevices();
  }, []);

  return (
      <div className={`Scan ${bgColor}`} >
        {((!!devices?.length) && deviceId) && <QrReader 
          constraints={{ deviceId }}
          scanDelay={1000}
          key={`qr-scan-dev-${deviceId}`}
          /* videoId={`qr-scan-dev-${deviceId}`} */
          onResult={onResult}
          videoContainerStyle={{ height: "100vh" , width: "auto", margin: "0", padding: "0" }}
          videoStyle={{ marginTop: "-100px"   }}
          containerStyle={{ }}
        />}
        {/* <div className="bg-zinc-400 absolute top-0">deviceId: {deviceId}</div> */}
        <SelectCam fetchDevices={fetchDevices} devices={devices} deviceId={deviceId} setDeviceId={setDeviceId} />
        <div className="absolute bottom-12 left-2 flex flex-row" >
          {waiting && <p>📡</p>}
        </div>
        {!!data && <div className="absolute bottom-20 left-2 flex flex-col-reverse bg-orange-100 rounded-md p-2 w-4/5 overflow-x-scroll" >
          <div>date: {data?.created_at}</div>
          <div>hash: {data?.hash}</div>
          <div>email: {data?.email}</div>
          <div>
            status:  {status==="success" && "valid ticket ✅"}
            {status==="exist" && "alredy checked ⚠️"}
            {status==="notfound" && "bad ticket ⛔"}
          </div>
          <div>{data?.message}</div>
          <button className="absolute right-0 top-0 bg-white rounded-xl" onClick={()=>resetData()}>❌</button>
        </div>}
        <div className="absolute bottom-40 right-2 text-5xl">
         {status==="success" && "✅"}
         {status==="exist" && "⚠️"}
         {status==="notfound" && "⛔"}
        </div>
      </div>
  );
}
  
export default Scan;